#thankyou {
  position: fixed;
  bottom: 0;
  right: 0;
  background: rgba(255, 255,255, 0.4);
  padding: 10px;
  border: lightgray;
  border-width: 1px;
  color: #4a4a4a;
  font-size: 12px;
  font-weight: bold;
  border-style: solid;
}
