.navbar {
  box-shadow: 0 4px 6px 0 hsla(0, 0%, 0%, 0.2);
}
.navbar-brand{
  text-align: center;
  height: 87px;
  line-height: 75px;
}
.navbar-item {
    color: #4a4a4a;
    padding: 0 1rem;
    vertical-align: middle;
    font-weight: bold;
    font-size: 2vw;
}
.right{float: right;}
.left{float: left;}

.logo img, .logo{
  height:90%;
}
.navbar-brand {
    text-align: center;
    height: 6vw;
    line-height: 6vw;
}
@media only screen and (max-width: 480px) {
  .navbar-brand {
      height: 10vw;
    }

  .logo.right{
    display:none ;
  }
  

}
