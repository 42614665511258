.App-clock{
  position: fixed;
  top: 90%;
  font-size: 50px;
  color: #4a4a4a;
  padding: 0 1rem;
}

@media only screen and (max-width: 880px) 
{
	.App-clock{
		display:none;
	}
}
  
