
body{
  border-top: 5px solid #00d1b2;
  font-family: BlinkMacSystemFont,-apple-system,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
  margin:0;
}
html, body, svg, .App, #root {
  width: 100%;
  height: 100%;
  margin: 0;
}
.label {
    fill: #191970;
    display: block;
    font-weight: 700;
    font-size: 5px;
    line-height: 1.3;
    position: absolute;
    padding: 10px 12px 0 12px;
    transition: opacity 400ms ease-in-out;
    border: solid 2px rgba(255,255,255,0.9);
    background-color: rgba(230,230,230,0.9);
    display: block;
}

.demand {
    fill: #191970;
    font-weight: 700;
}
.backG {
    fill: none;
    stroke-dasharray: 1;
    stroke-width: 4;
    stroke: #d5eaff;
    opacity: 0.9;
}
.line {
    fill: none;
    stroke: #88c5ff;
    stroke-dasharray: 3;
    stroke-width: 1;
    -webkit-animation: dashanimation 120s linear infinite forwards;
            animation: dashanimation 120s linear infinite forwards;
}
@-webkit-keyframes dashanimation {
  to {
    stroke-dashoffset: 1000; }
}
@keyframes dashanimation {
  to {
    stroke-dashoffset: 1000; }
}
.center{
  text-align:center
}
button.logo {
  padding: 5px 10px;
  border: none;
  background: none;
}
.reverse {
    animation-direction: reverse;
}
.zero {
  -webkit-animation:none !important;
          animation:none !important;
}

.App-clock{
  position: fixed;
  top: 90%;
  font-size: 50px;
  color: #4a4a4a;
  padding: 0 1rem;
}

@media only screen and (max-width: 880px) 
{
	.App-clock{
		display:none;
	}
}
  

.navbar {
  box-shadow: 0 4px 6px 0 hsla(0, 0%, 0%, 0.2);
}
.navbar-brand{
  text-align: center;
  height: 87px;
  line-height: 75px;
}
.navbar-item {
    color: #4a4a4a;
    padding: 0 1rem;
    vertical-align: middle;
    font-weight: bold;
    font-size: 2vw;
}
.right{float: right;}
.left{float: left;}

.logo img, .logo{
  height:90%;
}
.navbar-brand {
    text-align: center;
    height: 6vw;
    line-height: 6vw;
}
@media only screen and (max-width: 480px) {
  .navbar-brand {
      height: 10vw;
    }

  .logo.right{
    display:none ;
  }
  

}

#thankyou {
  position: fixed;
  bottom: 0;
  right: 0;
  background: rgba(255, 255,255, 0.4);
  padding: 10px;
  border: lightgray;
  border-width: 1px;
  color: #4a4a4a;
  font-size: 12px;
  font-weight: bold;
  border-style: solid;
}

