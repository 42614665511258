body{
  border-top: 5px solid #00d1b2;
  font-family: BlinkMacSystemFont,-apple-system,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
  margin:0;
}
html, body, svg, .App, #root {
  width: 100%;
  height: 100%;
  margin: 0;
}
.label {
    fill: #191970;
    display: block;
    font-weight: 700;
    font-size: 5px;
    line-height: 1.3;
    position: absolute;
    padding: 10px 12px 0 12px;
    transition: opacity 400ms ease-in-out;
    border: solid 2px rgba(255,255,255,0.9);
    background-color: rgba(230,230,230,0.9);
    display: block;
}

.demand {
    fill: #191970;
    font-weight: 700;
}
.backG {
    fill: none;
    stroke-dasharray: 1;
    stroke-width: 4;
    stroke: #d5eaff;
    opacity: 0.9;
}
.line {
    fill: none;
    stroke: #88c5ff;
    stroke-dasharray: 3;
    stroke-width: 1;
    animation: dashanimation 120s linear infinite forwards;
}
@keyframes dashanimation {
  to {
    stroke-dashoffset: 1000; }
}
.center{
  text-align:center
}
button.logo {
  padding: 5px 10px;
  border: none;
  background: none;
}
.reverse {
    animation-direction: reverse;
}
.zero {
  animation:none !important;
}
